<template>
  <icon-base
    icon-name="dollar-icon"
    :view-box="viewBox"
    :width="width"
    :height="height"
    :icon-color="iconColor"
  >
    <template v-if="!fill">
      <path
        d="M13.5 8C13.5 11.0376 11.0376 13.5 8 13.5C4.96243 13.5 2.5 11.0376 2.5 8C2.5 4.96243 4.96243 2.5 8 2.5C11.0376 2.5 13.5 4.96243 13.5 8Z"
        :stroke="fillColor"
        :stroke-width="state.strokeWidth"
      />
      <path
        d="M10 9.09329C10 9.34685 9.92996 9.58579 9.78988 9.81009C9.65499 10.0295 9.45266 10.2124 9.18288 10.3587C8.91829 10.5001 8.60441 10.583 8.24125 10.6073V11.1998H7.74319V10.6C7.22438 10.5561 6.80674 10.4099 6.49027 10.1612C6.1738 9.90761 6.01038 9.56628 6 9.13718H7.16732C7.19844 9.48826 7.3904 9.70038 7.74319 9.77352V8.37649C7.36965 8.28872 7.06874 8.20095 6.84047 8.11318C6.61219 8.0254 6.41505 7.884 6.24903 7.68895C6.08301 7.4939 6 7.22815 6 6.89169C6 6.46746 6.16083 6.12125 6.48249 5.85306C6.80934 5.58487 7.22957 5.43127 7.74319 5.39226V4.7998H8.24125V5.39226C8.7393 5.43127 9.13619 5.57268 9.43191 5.81649C9.73281 6.0603 9.90143 6.39676 9.93774 6.82586H8.76265C8.74708 6.68445 8.69261 6.56255 8.59922 6.46015C8.51102 6.35287 8.3917 6.27729 8.24125 6.2334V7.6158C8.63035 7.70845 8.93645 7.79866 9.15953 7.88643C9.38781 7.96933 9.58495 8.1083 9.75097 8.30335C9.91699 8.49352 10 8.75683 10 9.09329ZM7.13619 6.84049C7.13619 7.00141 7.18807 7.13306 7.29183 7.23546C7.39559 7.33299 7.54604 7.41344 7.74319 7.47683V6.21146C7.55642 6.23584 7.40856 6.30167 7.29961 6.40895C7.19066 6.51622 7.13619 6.66007 7.13619 6.84049ZM8.24125 9.78815C8.43839 9.75401 8.59144 9.67843 8.70039 9.5614C8.81453 9.44438 8.8716 9.30297 8.8716 9.13718C8.8716 8.97626 8.81712 8.84704 8.70817 8.74952C8.59922 8.652 8.44358 8.57154 8.24125 8.50815V9.78815Z"
        :fill="fillColor"
        :stroke="fillColor"
        :stroke-width="state.strokeWidth"
      />
    </template>
    <template v-if="fill">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14ZM9.78988 9.81029C9.92996 9.58598 10 9.34705 10 9.09349C10 8.75703 9.91699 8.49371 9.75097 8.30354C9.58495 8.1085 9.38781 7.96952 9.15953 7.88663C8.93645 7.79886 8.63035 7.70865 8.24125 7.616V6.2336C8.3917 6.27749 8.51102 6.35307 8.59922 6.46034C8.69261 6.56274 8.74708 6.68465 8.76265 6.82606H9.93774C9.90143 6.39695 9.73281 6.0605 9.43191 5.81669C9.13619 5.57288 8.7393 5.43147 8.24125 5.39246V4.8H7.74319V5.39246C7.22957 5.43147 6.80934 5.58507 6.48249 5.85326C6.16083 6.12145 6 6.46766 6 6.89189C6 7.22834 6.08301 7.49409 6.24903 7.68914C6.41505 7.88419 6.61219 8.0256 6.84047 8.11337C7.06874 8.20114 7.36965 8.28891 7.74319 8.37669V9.77371C7.3904 9.70057 7.19844 9.48846 7.16732 9.13737H6C6.01038 9.56648 6.1738 9.90781 6.49027 10.1614C6.80674 10.4101 7.22438 10.5563 7.74319 10.6002V11.2H8.24125V10.6075C8.60441 10.5832 8.91829 10.5003 9.18288 10.3589C9.45266 10.2126 9.65499 10.0297 9.78988 9.81029ZM7.29183 7.23566C7.18807 7.13326 7.13619 7.0016 7.13619 6.84069C7.13619 6.66027 7.19066 6.51642 7.29961 6.40914C7.40856 6.30187 7.55642 6.23604 7.74319 6.21166V7.47703C7.54604 7.41364 7.39559 7.33318 7.29183 7.23566ZM8.70039 9.5616C8.59144 9.67863 8.43839 9.75421 8.24125 9.78834V8.50834C8.44358 8.57173 8.59922 8.65219 8.70817 8.74971C8.81712 8.84724 8.8716 8.97646 8.8716 9.13737C8.8716 9.30316 8.81453 9.44457 8.70039 9.5616Z"
        :fill="fillColor"
      />
    </template>
  </icon-base>
</template>
<script>
import { computed, reactive } from "vue";
import IconBase from "../../common/IconBase";

export default {
  name: "DollarIcon",
  components: { IconBase },
  props: {
    viewBox: {
      type: String,
      default: "0 0 16 16",
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 16,
    },
    iconColor: {
      type: String,
      default: "currentColor",
    },
    fillColor: {
      type: String,
      default: "#D0D5DE",
    },
    fill: {
      type: Boolean,
      default: false,
    },
    strokeSize: {
      type: String,
      default: "thin",
      validator(value) {
        return ["thin", "normal"].includes(value);
      },
    },
  },
  setup(props) {
    const state = reactive({
      strokeWidth: computed(() => {
        if (props.strokeSize === "normal") {
          return 0.5;
        }
        return 0;
      }),
    });

    return { state };
  },
};
</script>
