<template>
  <div class="onboarding-page__wrapper">
    <div class="purpose-page">
      <h2>빅크 스튜디오의 어떤 점을 <br class="mo" />기대하시나요?</h2>
      <p class="sub-text-s2 text-gray-second">
        크리에이터님이 빅크에서 기대하시는 점을 알려주세요. (복수 선택 가능)
      </p>

      <ul class="purpose-list">
        <li>
          <div class="purpose-item" @click="actions.updateCheckPurpose(1)">
            <input-checkbox
              :default-value="state.checkedPurpose1"
            ></input-checkbox>
            <div class="content">
              <p class="main-txt b-text-2">
                {{ state.reason1 }}
              </p>
              <p class="c-text text-purple-20 pc sub-txt">
                빅크 상위 크리에이터는 월 평균 1,022만원 매출을 내고 있어요
              </p>
              <p class="e-text-1 text-purple-20 mo sub-txt">
                빅크 상위 크리에이터는 월 평균 1,022만원 매출을 내고 있어요
              </p>
            </div>
            <dollar-icon
              class="end-icon pc"
              fill-color="#D0D5DE"
              width="20"
              height="20"
              :fill="true"
            ></dollar-icon>
          </div>
        </li>
        <li>
          <div class="purpose-item" @click="actions.updateCheckPurpose(2)">
            <input-checkbox
              :default-value="state.checkedPurpose2"
            ></input-checkbox>
            <div class="content">
              <p class="main-txt b-text-2">
                {{ state.reason2 }}
              </p>
              <p class="c-text text-purple-20 pc sub-txt">
                인사이트 기능으로 나만의 오디언스 또는 구매자 데이터를 분석 및
                운영할 수 있어요
              </p>
              <p class="e-text-1 text-purple-20 mo sub-txt">
                나만의 오디언스 또는 구매자 데이터를 분석 및 운영할 수 있어요
              </p>
            </div>
            <dashboard-icon
              class="end-icon pc"
              fill-color="#D0D5DE"
              width="20"
              height="20"
              :fill="true"
            ></dashboard-icon>
          </div>
        </li>
        <li>
          <div class="purpose-item" @click="actions.updateCheckPurpose(3)">
            <input-checkbox
              :default-value="state.checkedPurpose3"
            ></input-checkbox>
            <div class="content">
              <p class="main-txt b-text-2">{{ state.reason3 }}</p>
              <p class="c-text text-purple-20 pc sub-txt">
                유료 라이브, 커뮤니티 기능으로 팬들과 쉽게 소통이 가능해요
              </p>
              <p class="e-text-1 text-purple-20 mo sub-txt">
                유료 라이브, 커뮤니티로 팬들과 쉽게 소통이 가능해요
              </p>
            </div>
            <community-icon
              class="end-icon pc"
              fill-color="#D0D5DE"
              width="20"
              height="20"
              :fill="true"
            ></community-icon>
          </div>
        </li>
        <li>
          <div class="purpose-item" @click="actions.updateCheckPurpose(4)">
            <input-checkbox
              :default-value="state.checkedPurpose4"
            ></input-checkbox>
            <div class="content">
              <p class="main-txt b-text-2">{{ state.reason4 }}</p>
              <p class="c-text text-purple-20 pc sub-txt">
                빅크의 브랜드 홈은 링크 뿐 아니라 다양한 위젯을 추가할 수 있어요
              </p>
              <p class="e-text-1 text-purple-20 mo sub-txt">
                브랜드 홈은 링크 뿐 아니라 다양한 위젯을 추가할 수 있어요
              </p>
            </div>
            <brand-home-icon
              class="end-icon pc"
              fill-color="#D0D5DE"
              width="20"
              height="20"
              :fill="true"
            ></brand-home-icon>
          </div>
        </li>
        <li>
          <div
            class="purpose-item etc"
            :class="{ error: state.etcError }"
            @click.stop="actions.updateCheckPurpose(5)"
          >
            <input-checkbox
              :default-value="state.checkedPurpose5"
            ></input-checkbox>
            <div class="content">
              <p class="main-txt b-text-2">기타 :</p>
            </div>
            <textarea
              ref="reasonFiveTextarea"
              v-model="state.reason5"
              class="etc-textarea b-text-2"
              :disabled="!state.checkedPurpose5"
              @input="actions.resizeReasonFiveTextarea()"
              @click.stop="actions.onClickTextArea($event)"
            ></textarea>
          </div>
          <p v-if="state.etcError" class="sub-text-s3 text-red-50">
            기대하시는 점을 작성해주세요
          </p>
        </li>
      </ul>

      <button-basic
        class="save-btn"
        text="다음"
        :disabled="state.disableSaveBtn"
        @action="actions.savePurpose()"
      ></button-basic>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import InputCheckbox from "../../../../components/console/inputs/InputCheckbox";
import BrandHomeIcon from "../../../../components/console/icons/BrandHomeIcon";
import ButtonBasic from "../../../../components/console/buttons/ButtonBasic";
import { computed, onMounted, reactive, ref } from "vue";
import CommunityIcon from "../../../../components/console/icons/CommunityIcon";
import DollarIcon from "../../../../components/console/icons/DollarIcon";
import DashboardIcon from "../../../../components/console/icons/DashboardIcon";

export default {
  name: "OnBoardingPurpose",
  components: {
    DashboardIcon,
    DollarIcon,
    CommunityIcon,
    ButtonBasic,
    BrandHomeIcon,
    InputCheckbox,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const reasonFiveTextarea = ref();

    const state = reactive({
      checkedPurpose1: false,
      checkedPurpose2: false,
      checkedPurpose3: false,
      checkedPurpose4: false,
      checkedPurpose5: false,
      reason1: "내 콘텐츠로 꾸준한 수익을 내고 싶어요",
      reason2: "팔로우하는 팬과 상품 데이터가 궁금해요",
      reason3: "프라이빗 팬과 소통하고 싶어요",
      reason4: "나만의 브랜드 홈 페이지가 필요해요",
      reason5: "",
      disableSaveBtn: computed(() => {
        return (
          (!state.checkedPurpose1 &&
            !state.checkedPurpose2 &&
            !state.checkedPurpose3 &&
            !state.checkedPurpose4 &&
            !state.checkedPurpose5) ||
          state.etcError
        );
      }),
      etcError: computed(() => {
        return state.checkedPurpose5 && !state.reason5;
      }),
    });

    onMounted(() => {
      reasonFiveTextarea.value.style.height = "18px";
    });

    const actions = {
      savePurpose: () => {
        let selectedPurposes = [];
        if (state.checkedPurpose1) {
          selectedPurposes.push(state.reason1);
        }
        if (state.checkedPurpose2) {
          selectedPurposes.push(state.reason2);
        }
        if (state.checkedPurpose3) {
          selectedPurposes.push(state.reason3);
        }
        if (state.checkedPurpose4) {
          selectedPurposes.push(state.reason4);
        }
        if (state.checkedPurpose5) {
          selectedPurposes.push(state.reason5);
        }

        store
          .dispatch("onBoardings/postJoinPurpose", {
            purpose: selectedPurposes,
          })
          .then(() => {
            router.push({ name: "console.onboarding.channel" });
          });
      },
      updateCheckPurpose: (value) => {
        state[`checkedPurpose${value}`] = !state[`checkedPurpose${value}`];

        if (state.checkedPurpose5 && value === 5) {
          // note textarea 에 disabled = false 가 적용되는 시간이 필요함.
          setTimeout(() => {
            reasonFiveTextarea.value.focus();
          }, 50);
        } else {
          reasonFiveTextarea.value.blur();
        }
      },
      resizeReasonFiveTextarea: () => {
        reasonFiveTextarea.value.style.height = "18px";
        reasonFiveTextarea.value.style.height = `${reasonFiveTextarea.value.scrollHeight}px`;
      },
      // note ios 기기에서 가상 focus event가 안되는 이슈 => input focus 할때, 버블링이 일어나지 않도록 하기 위함.
      onClickTextArea: (e) => {
        e.preventDefault();
      },
    };

    return { state, actions, reasonFiveTextarea };
  },
};
</script>

<style src="./style.css" scoped></style>
